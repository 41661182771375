<template>
  <v-container style="padding-top: 0; padding-bottom: 0">
    <div
      class="text-left font-size-16 m-b-20 m-t-20"
      style="color: black; font-style: italic"
    >
      Προσαρμόστε τη διανομή στα μέτρα σας. Βρείτε τις επιλογές που σας
      ταιριάζουν παρακάτω.
    </div>
    <div v-for="card in courierCards" :key="card.id" class="m-b-10">
      <CourierManagementButton
        :cardData="card"
        :isActive="card.id === isComponent"
        @activateComponent="activateComponent(card.id)"
      >
        <template v-if="card.id === isComponent" v-slot:cardForm>
          <div class="form_layout">
            <component
              :is="renderedComponent"
              :voucherData="voucherData"
              :isDisabled="isDisabled"
              @closeForm="isComponent = null"
            ></component>
          </div>
        </template>
      </CourierManagementButton>
    </div>
  </v-container>
</template>

<script>
import {
  faPencil,
  faEuroSign,
  faComment,
  faClock,
  faPhone,
  faWalking,
  faBan,
  faMapPin,
} from "@fortawesome/pro-solid-svg-icons";
export default {
  components: {
    CourierManagementButton: () =>
      import(
        "@/views/LiveTracking/LiveTracking-Components/CourierManagementButton"
      ),
    PersonalDetails: () =>
      import(
        "@/views/LiveTracking/LiveTracking-Components/CardForms/PersonalDetails"
      ),
    ChangeAddress: () =>
      import(
        "@/views/LiveTracking/LiveTracking-Components/CardForms/ChangeAddress"
      ),
    Payment: () =>
      import("@/views/LiveTracking/LiveTracking-Components/CardForms/Payment"),
    CommentForRider: () =>
      import(
        "@/views/LiveTracking/LiveTracking-Components/CardForms/CommentForRider"
      ),
    CancelVoucher: () =>
      import(
        "@/views/LiveTracking/LiveTracking-Components/CardForms/CancelVoucher"
      ),
  },
  props: {
    voucherData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isComponent: null,
      faPencil,
      faEuroSign,
      faComment,
      faClock,
      faPhone,
      faWalking,
      faBan,
      faMapPin,
      courierCards: [
        {
          id: "personal",
          title: "Στοιχεία Παραλήπτη",
          icon: faPencil,
        },
        {
          id: "changeAddress",
          title: "Διεύθυνση Παραλήπτη",
          icon: faMapPin,
        },
        {
          id: "payment",
          title: "Αντικαταβολή",
          icon: faEuroSign,
        },
        {
          id: "commentForRider",
          title: "Σχόλια για τον οδηγό",
          icon: faComment,
        },
        // {
        //   id: "cancel",
        //   title: "Ακύρωση παράδοσης",
        //   icon: faBan,
        // },
        // {
        //   id: "phoneCall",
        //   title: "Κλήση πριν την παράδοση",
        //   icon: faPhone,
        // },
        // {
        //   id: "customerPickup",
        //   title: "Παραλαβή από κατάστημα",
        //   icon: faWalking,
        // },
        // {
        //   id: "deliverySchedule",
        //   title: "Προγραμματισμός Παράδοσης",
        //   icon: faClock,
        // },
      ],
    };
  },
  computed: {
    renderedComponent() {
      const obj = {
        personal: "PersonalDetails",
        changeAddress: "ChangeAddress",
        payment: "Payment",
        commentForRider: "CommentForRider",
        // cancel: "CancelVoucher",
      };
      return obj[this.isComponent];
    },
    isDisabled() {
      return this.voucherData.completed || this.voucherData.cancelled;
    },
  },
  methods: {
    activateComponent(id) {
      if (this.isComponent === id) {
        this.isComponent = null;
      } else {
        this.isComponent = id;
      }
    },
    handleInitialButtonConfig() {
      if (this.voucherData) {
        let paymentIndex = this.courierCards.findIndex(
          (card) => card.id === "payment"
        );
        let personalIndex = this.courierCards.findIndex(
          (card) => card.id === "personal"
        );
        let changeAddress = this.courierCards.findIndex(
          (card) => card.id === "changeAddress"
        );
        let isApprroved = this.voucherData.trackingActions.approvedDetails;
        this.$set(
          this.courierCards[personalIndex],
          "color",
          isApprroved ? "initial" : "error"
        );
        this.$set(
          this.courierCards[changeAddress],
          "color",
          isApprroved ? "initial" : "error"
        );
        if (this.voucherData.needPayment) {
          this.$set(
            this.courierCards[paymentIndex],
            "title",
            `Αντικαταβολή: ${this.voucherData.paymentAmount.toFixed(2)}€`
          );
        } else {
          this.courierCards.splice(paymentIndex, 1);
        }
      }
    },
  },
  mounted() {
    this.handleInitialButtonConfig();
  },
};
</script>

<style lang="scss">
.form_layout {
  margin: 0 8px;
  width: 100%;
  border: 1px solid orange;
  border-radius: 10px;
  padding: 20px 10px;
}
</style>
